@import '../../../../styles/variables';
@import 'bootstrap/scss/mixins/breakpoints';

.socialNav {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  height: 90px;

  @include media-breakpoint-up(md) {
    gap: 45px;
  }
}
