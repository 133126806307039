@import 'styles/variables';
@import 'bootstrap/scss/mixins/breakpoints';
@import 'bootstrap/scss/vendor/rfs';

.skip-link-container {
  position: relative;
  z-index: 100;
  height: auto;
  .skip-link {
    position: absolute;
    clip: rect(0 0 0 0) !important;
    margin: 0 !important;
    padding: 0.625rem 1.875rem;
    top: rfs-value(37px);
    left: 50%;
    transform: translateX(-50%);

    &:active,
    &:focus {
      clip: auto !important;
      opacity: 1;
    }
  }
}
