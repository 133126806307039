@import 'styles/variables';
@import 'bootstrap/scss/mixins';

.video {
  object-fit: none;
  width: 100%;

  @include media-breakpoint-up(xxl) {
    object-fit: cover;
  }
}

.left {
  &.top {
    object-position: left top;
  }
  &.center {
    object-position: left center;
  }
  &.bottom {
    object-position: left bottom;
  }
}

.center {
  &.top {
    object-position: center top;
  }
  &.center {
    object-position: center center;
  }
  &.bottom {
    object-position: center bottom;
  }
}

.right {
  &.top {
    object-position: right top;
  }
  &.center {
    object-position: right center;
  }
  &.bottom {
    object-position: right bottom;
  }
}
