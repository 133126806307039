@import '../../../../styles/variables';
@import 'bootstrap/scss/vendor/rfs';
@import 'bootstrap/scss/mixins/breakpoints';

.copyright {
  background: #fff;
  font-family: $font-family-generic;
  font-size: rfs-value(12px);
  line-height: 1.7;
}

.copyrightLinks {
  margin: 0;
  padding: 15px;
  text-align: center;
  text-decoration: underline;

  a {
    color: black !important;
    font-size: rfs-value(12px) !important;

    &:hover {
      text-decoration: underline;
    }
  }

  @include media-breakpoint-up(md) {
    text-decoration: none;

    a {
      text-decoration: none;
    }
  }
}

.copyrightText {
  padding: 15px 30px;
  border-top: 1px solid $nikon-light-grey;
  text-align: center;

  @include media-breakpoint-up(md) {
    border-top: 0;
    text-align: right;
  }
}
