@import 'bootstrap/scss/vendor/rfs';

.description {
  font-size: rfs-value(13.6px);
  line-height: 1.375;
  margin-bottom: rfs-value(10px);

  p {
    margin-bottom: rfs-value(10px);

    &:last-child {
      margin: 0;
    }
  }
}
