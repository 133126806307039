.video-container {
  width: 100%;
  height: 100%;
  max-height: 600px;
  background-color: #000000;
}

.item-centered {
  margin: 0 auto;
}
