@import 'styles/variables';
@import 'bootstrap/scss/mixins/breakpoints';
@import 'bootstrap/scss/vendor/rfs';

.plain-features {
  @include media-breakpoint-up(md) {
    column-count: 2;
    gap: rfs-value(30px);
    break-inside: avoid-column;

    &:has(> div:only-child) {
      column-count: 1;
      max-width: 750px;
      margin: 0 auto;
      text-align: center;
    }
  }

  h2 {
    margin-bottom: rfs-value(30px);
    font-size: rfs-value(37.3333333328px);
  }
}

.plain-features-block {
  break-inside: avoid-column;
  padding: rfs-value(0 15px);

  & + & {
    margin-top: rfs-value(30px);
  }
  h3 {
    font-size: rfs-value(24px);
  }
  ol {
    list-style: decimal;
  }
  ul {
    list-style: disc;
  }
  .description {
    font-size: rfs-value(14px);

    p {
      margin-bottom: rfs-value(30px);
    }
  }
}
