@import 'styles/variables';
@import 'bootstrap/scss/vendor/rfs';

.button {
  position: relative;
  margin: rfs-value(0 0 0 12px) !important;
  font-family: $icon-font;
  background: rgba($nikon-text-dark-grey, 0.8) !important;
  color: $nikon-white;
  opacity: 1 !important;
  width: 32px !important;
  height: 32px !important;

  &:before {
    position: relative;
    font-size: rfs-value(13px) !important;
    opacity: 0.9;
    top: 1px;
    left: 0;
  }
}

.pause::before {
  content: '▶';
  left: 2px;
}

.play::before {
  content: '';
}
