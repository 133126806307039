@import 'styles/variables';
@import 'bootstrap/scss/vendor/rfs';

.rating-stars {
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 2px;
}

.stars {
  display: inline-block;
  font-size: 16px;
  line-height: 1;
  font-family: $icon-font;
  letter-spacing: 4px;
  position: relative;
  line-height: 1.7;
  margin-top: -5px;
  margin-bottom: -6.2px;

  &::before,
  &::after {
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &::before {
    content: '⋆⋆⋆⋆⋆';
    background-color: #666;
    text-shadow: 0 0 1px $nikon-dark-grey;
  }

  &::after {
    background-color: $nikon-yellow;
    position: absolute;
    left: 0;
    top: 0;
  }
  &.rating-0::after {
    content: '';
  }
  &.rating-0-5::after {
    content: '\e1a1';
  }
  &.rating-1::after {
    content: '⋆';
  }
  &.rating-1-5::after {
    content: '⋆\e1a1';
  }
  &.rating-2::after {
    content: '⋆⋆';
  }
  &.rating-2-5::after {
    content: '⋆⋆\e1a1';
  }
  &.rating-3::after {
    content: '⋆⋆⋆';
  }
  &.rating-3-5::after {
    content: '⋆⋆⋆\e1a1';
  }
  &.rating-4::after {
    content: '⋆⋆⋆⋆';
  }
  &.rating-4-5::after {
    content: '⋆⋆⋆⋆\e1a1';
  }
  &.rating-5::after {
    content: '⋆⋆⋆⋆⋆';
  }
}

.count {
  font-family: $font-family-base;
  font-size: rfs-value(11px);
  color: #666;
  line-height: 1;
  margin-left: 1px;
}
