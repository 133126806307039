@import 'styles/fonts';
@import 'styles/variables';
@import 'bootstrap/scss/mixins/breakpoints';

.linkLiContainer {
  border-bottom: none;
  display: inline-block;
  width: auto;
  padding-right: 15px;
  height: 32px;
}

.linkBtn {
  margin-right: 30px;
  position: relative;
  display: inline-block;
  color: #404040;
  font-size: 1rem;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
  background: transparent;
  border: none;
  font-family: $headings-font-family;
  border-radius: 0;
  line-height: 2;

  &:hover {
    background: transparent;
    color: #404040;
    text-decoration: underline;
  }

  &:focus {
    -webkit-box-shadow: 0 0 0 2px #fff, 0 0 0 4px #000, 0 0 4px 8px #fff;
    box-shadow: 0 0 0 2px #fff, 0 0 0 4px #000, 0 0 4px 8px #fff;
    outline: 2px transparent solid;
    background: transparent;
  }
}

.linkIcon {
  display: inline-block;
  padding-left: 5px;
  margin-right: -15px;

  &::before {
    content: url(/icons/rightArrowYellow.svg);
    vertical-align: middle;
  }
}
