.video-iframe,
.video-iframe-carousel {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.video-wrapper {
  position: relative;

  &,
  &.horizontal {
    padding: 56.25% 0 0 0;
  }
  &.vertical {
    padding: 177.7777% 0 0 0;
  }
  &.square {
    padding: 100% 0 0 0;
  }
}
