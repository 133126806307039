@import 'styles/variables';
@import 'bootstrap/scss/mixins/breakpoints';

.button-row {
  position: relative;
  text-align: center;
  margin: 0 auto;
}

.nkn-resp-title {
  font-size: 2rem;
  margin: 0 0 15px;
  font-weight: 600;
  white-space: normal;
  line-height: 1.033333333333333 !important;
  text-shadow: none !important;
  text-rendering: geometricPrecision;
  font-family: $headings-font-family;

  @include media-breakpoint-up(md) {
    font-size: 3rem;
  }
}

.nkn-resp-description {
  font-family: $headings-font-family;
  font-weight: 400;
  text-shadow: none !important;
  margin-bottom: 0;
  line-height: 1.375 !important;
  font-size: 0.875rem;

  @include media-breakpoint-up(md) {
    font-size: 1.5rem;
    line-height: 1.4 !important;
  }
}

.buttons {
  margin-top: 15px;
  display: inline-flex;
  flex-direction: column;
  width: 100%;

  @include media-breakpoint-up(md) {
    display: block;
  }
}

.button-row-btn {
  font-family: $headings-font-family;
  margin-top: 15px;
  position: relative;
  border: 1px solid transparent;
  border-radius: 90px;
  cursor: pointer;
  display: inline-block;
  font-size: 0.75rem;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 1.2;
  min-width: 140px;
  padding: 10px 32px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  text-shadow: none;
  vertical-align: middle;

  @include media-breakpoint-up(md) {
    align-self: center;
    flex: 1;
    margin: 15px 7px 0;
    padding: 15px 52px;
    font-size: 0.8rem;
  }
}
