@import 'styles/variables';
@import 'bootstrap/scss/mixins/breakpoints';
@import 'bootstrap/scss/vendor/rfs';

.inlineContainer {
  padding: 0;
  font-family: $font-family-base;
  color: $body-color;
  margin-bottom: 60px;

  &::after,
  &::before {
    content: ' ';
    display: table;
  }
  .inlineRow {
    &::after,
    &::before {
      content: ' ';
      display: table;
    }
  }
  .contentWrap {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background: 0 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 25px 15px;
  }
  .content {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    max-width: 350px;
    width: 100%;
    height: auto;
    padding: 6%;
    position: relative;

    @include media-breakpoint-up(md) {
      padding: 8%;
    }
  }
  .nkn-inline-icon {
    height: 45px;
    margin-bottom: 20px;
    width: auto;

    @include media-breakpoint-up(lg) {
      height: 90px;
      margin-bottom: 40px;
    }
  }
  .nkn-inline-title {
    font-weight: 600;
    margin: 0 0 15px;
    margin-top: 0;
    width: 100%;
    text-align: left;
    font-size: 2.5rem;
    line-height: 0.95;

    @include media-breakpoint-up(md) {
      font-size: calc(40px + (55 - 40) * ((100vw - 768px) / (1440 - 768)));
      line-height: 0.964;
    }

    @include media-breakpoint-up(xxl) {
      font-size: 3.438rem;
    }
  }
  .nkn-inline-description {
    font-size: 1rem;
  }
  .item-wrapper {
    position: relative;
    width: 100%;
  }
  .overlay {
    position: absolute;
    display: none;
    width: 100%;
    height: 100%;
    top: -50%;
    left: 1%;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0.7;
    z-index: 1;
  }

  :global(.carousel-one-text-list) {
    margin: 0;
    padding: 0;

    :global(.slick-list) {
      padding: 0 15% 0 0 !important;
    }
    :global(.slick-slide) {
      height: 250px;
      overflow: hidden;
      position: relative;

      @include media-breakpoint-up(md) {
        height: 380px;
      }
      @include media-breakpoint-up(lg) {
        height: 500px;
      }
    }
    img {
      height: 100%;
      left: 50%;
      min-height: 100%;
      min-width: 101%;
      object-fit: cover;
      position: absolute;
      top: 50%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      width: 100%;

      @include media-breakpoint-up(md) {
        width: auto;
      }
    }

    .one-liner-text {
      color: $nikon-bg-color;
      position: absolute;
      background: rgba(0, 0, 0, 0.9);
      bottom: 20px;
      font-size: 1.25rem;
      font-weight: 700;
      left: 20px;
      letter-spacing: 0;
      line-height: 0.95;
      max-width: 60%;
      padding: 10px;
      text-align: left;
      text-shadow: none;
      text-transform: none;
      top: auto;
      -webkit-transform: translate(0, 0);
      -ms-transform: translate(0, 0);
      transform: translate(0, 0);

      &::before {
        display: none;
      }

      @include media-breakpoint-up(md) {
        font-size: calc(24px + (32 - 24) * ((100vw - 768px) / (1440 - 768)));
        line-height: 0.976;
      }
      @include media-breakpoint-up(xxl) {
        font-size: 2rem;
      }
    }
    :global(.slick-slide.slick-current + .slick-slide:after) {
      background: rgba(0, 0, 0, 0.5);
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }

    :global(.slick-arrow) {
      opacity: 1;
      position: absolute;
      top: 50%;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      -webkit-transition: opacity 0.25s ease-in-out;
      transition: opacity 0.25s ease-in-out;
      width: 21px;

      &:focus,
      &:hover {
        opacity: 0.75;
      }
      @include media-breakpoint-up(md) {
        width: 35px;
      }
    }
    :global(.slick-prev) {
      display: none !important;
    }

    :global(.slick-next) {
      right: 15px;
      border-radius: 50%;
      background: $nikon-bg-color;
      width: rfs-value(45px);
      height: rfs-value(45px);

      &::before {
        background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/></svg>");
        font-size: rfs-value(22px);
        color: transparent;
      }

      &:hover {
        opacity: 1;
      }

      &:active,
      &:focus {
        opacity: 1;
        box-shadow: 0 0 0 2px $nikon-bg-color, 0 0 0 4px $body-color, 0 0 4px 8px $nikon-bg-color;
        outline: 2px solid transparent;
      }

      @include media-breakpoint-up(md) {
        right: 30px;
      }
    }

    button {
      background: 0 0;
      border: none;
      padding: 0;

      svg {
        height: 40px;
        width: 21px;

        @include media-breakpoint-up(md) {
          height: 65px;
          width: 35px;
        }
      }
    }
  }
}
