@import 'styles/variables';
@import 'bootstrap/scss/mixins/breakpoints';

.one-video {
  :global(.container) {
    margin-bottom: 60px;
  }
}

.full-width {
  margin-bottom: 60px;

  .video-container {
    max-height: 350px;
    overflow: hidden;

    @include media-breakpoint-up(md) {
      max-height: 640px;
    }

    & > div {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
