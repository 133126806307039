@import 'styles/variables';
@import 'bootstrap/scss/mixins';

.video-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000000;
}

.video-info {
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  line-height: 1;
  font-size: rfs-value(10px);
  opacity: 1;
  padding: rfs-value(12px 15px);
  position: absolute;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.9);
  cursor: pointer;
  text-decoration: underline;
  transition: background 250ms ease-out;
  text-align: center;
  bottom: 15px;
  left: 50%;
  transform: translate(-50%, 0);

  :global(.carousel-item) & {
    bottom: 60px;
  }

  &:hover {
    background: rgb(0, 0, 0);
    text-decoration: none;
  }

  @include media-breakpoint-up(md) {
    bottom: 45px;
    left: auto;
    right: 30px;
    transform: none;
  }
}
