@import 'styles/variables';
@import 'bootstrap/scss/mixins/breakpoints';
@import 'bootstrap/scss/vendor/rfs';

.item-container {
  position: relative;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);

  .item-centered {
    margin: 0 auto;
  }

  .image-justification-left,
  .image-justification-center,
  .image-justification-right {
    flex-direction: column;

    @include media-breakpoint-up(md) {
      .content {
        display: flex;
        flex-wrap: wrap;
        height: 100%;
        align-content: center;
        margin: 0;
      }
    }
  }

  @include media-breakpoint-up(md) {
    .image-justification-left {
      flex-direction: row;
    }
    .image-justification-right {
      flex-direction: row-reverse;
    }
  }

  .content {
    text-align: center;
    max-width: rfs-value(750px);
    margin: rfs-value(15px) auto 0;
    padding: 0;

    @include media-breakpoint-up(lg) {
      padding: rfs-value(0 30px);
    }
  }

  &.grid-1 .content {
    padding: 0 15px;
  }

  @include media-breakpoint-up(md) {
    .text-justification-left .content {
      text-align: left;
    }
    .text-justification-right .content {
      text-align: right;
    }
    .text-justification-center .content {
      text-align: center;
    }
  }

  h3 {
    width: 100%;
    font-size: calc(40px + (55 - 40) * ((100vw - 768px) / (1440 - 768)));
    line-height: 0.964;
    margin-bottom: rfs-value(15px);

    @include media-breakpoint-up(md) {
      font-size: rfs-value(30px);
      line-height: 1.2;
    }

    :global(.one-to-four-column.grid-1) & {
      font-size: rfs-value(40px);
      line-height: 0.95;

      @include media-breakpoint-up(md) {
        font-size: calc(40px + (55 - 40) * ((100vw - 768px) / (1440 - 768)));
        line-height: 0.964;
      }
      @include media-breakpoint-up(xxl) {
        font-size: rfs-value(55px);
      }
    }
  }

  .description {
    width: 100%;
    font-size: rfs-value(16px);
    line-height: rfs-value(22px);

    & + .content-link {
      margin: rfs-value(25px 0 0);
    }
  }

  .content-link {
    color: $nikon-dark-grey;
    display: block;
    font-size: rfs-value(16px);
    font-weight: 700;
    line-height: 1.375;
    text-decoration: none;
    text-transform: uppercase;

    &:hover {
      text-decoration: underline;
    }

    .icon {
      display: inline-block;
      margin-left: rfs-value(1px);
      font-size: 40px;
      position: relative;

      &::before {
        content: '';
        width: 14px;
        height: 14px;
        background: url(/icons/rightArrowYellow.svg) center no-repeat;
        background-size: contain;
        position: absolute;
        bottom: -1px;
        left: 0;
      }
    }
  }

  &.grid-1 {
    &.left,
    &.center,
    &.right {
      .content {
        color: white;
        background: rgba(0, 0, 0, 0.8);
        padding: rfs-value(30px 25px);
        margin: 0;
        max-width: none;

        h3,
        .description {
          text-align: left;
        }
      }
      .content-link {
        color: $nikon-white;
        text-shadow: 0 1px 1px rgba(0, 0, 0, 0.9);
        font-size: rfs-value(14px);
        line-height: 1.143;
        letter-spacing: 1px;
        text-decoration: underline;
      }
      img {
        height: 58vw;
        max-height: 600px;
        min-height: 325px;
        object-fit: cover;

        @include media-breakpoint-up(md) {
          height: 640px;
          max-height: none;
        }
      }
    }
  }

  :global(.grid-1.image-full-width-edge-to-edge) & {
    padding: 0;

    img {
      width: 100% !important;
      height: 58vw;
      max-height: 600px;
      min-height: 325px;
      object-fit: cover;

      @include media-breakpoint-up(md) {
        height: 640px;
        max-height: none;
      }
    }
  }

  @include media-breakpoint-up(md) {
    padding: 0;

    .content {
      margin-top: rfs-value(30px);
    }

    &.grid-1 {
      &.left,
      &.center,
      &.right {
        width: auto;
        height: 100%;
        padding: 0;

        .content {
          position: absolute;
          top: 50%;
          margin-top: 0;
          padding: rfs-value(40px 35px);
          width: 50%;
          max-width: 480px;
          border-radius: 15px;
        }
      }

      &.left {
        .content {
          text-align: left;
          left: 10%;
          transform: translate(0, -50%);
        }
      }

      &.center {
        .content {
          text-align: center;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      &.right {
        .content {
          text-align: left;
          right: 10%;
          transform: translate(0, -50%);
        }
      }
    }
  }
}
