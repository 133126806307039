@import 'styles/variables';
@import 'bootstrap/scss/mixins/breakpoints';
@import 'bootstrap/scss/vendor/rfs';

.lne-article-category-block {
  margin-bottom: rfs-value(40px);
  margin-top: rfs-value(40px);

  @include media-breakpoint-up(md) {
    padding: rfs-value(0 15px);
    margin-bottom: rfs-value(90px);
    margin-top: rfs-value(120px);
  }
}

.category {
  position: relative;
  margin-bottom: rfs-value(15px);
  text-align: center;

  @include media-breakpoint-up(md) {
    margin-bottom: rfs-value(30px);
  }

  a {
    display: block;
    width: 100%;
    height: 100%;
    cursor: pointer;

    img {
      filter: brightness(0.7);
      transition: filter 0.25s ease-out;
    }

    &:hover {
      img {
        filter: brightness(0.5);
      }
    }
  }
}

.info {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: $nikon-white;
  width: 100%;
  padding: rfs-value(15px);

  h2 {
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.9);
    font-size: rfs-value(48px);
    line-height: 1.2;
    margin-bottom: rfs-value(10px);
  }
  p {
    letter-spacing: rfs-value(4px);
    font-size: rfs-value(14px);
    font-weight: 700;
    line-height: 1.5;
    margin: 0;
    text-transform: uppercase;
  }
}
