@import 'styles/variables';
@import 'bootstrap/scss/mixins/breakpoints';
@import 'bootstrap/scss/vendor/rfs';

.nkn-faq-container {
  font-family: $headings-font-family;

  abbr {
    border: none;
    text-decoration: none;
  }
  p {
    margin: 0 0 10px;
  }
  a {
    color: #1d3994;
    text-decoration: underline;
  }
  :global(.accordion .accordion-item) {
    background: #fff;
    border: none;
    border-bottom: 1px solid #000;
    border-radius: 0;
    box-shadow: none;
    margin-bottom: 0;
    text-align: left;
  }
  :global(.accordion-header .accordion-button) {
    font-size: rfs-value(18px);
    line-height: 1;
    margin-bottom: 0;
    margin-top: 0;
    border-radius: 0;
    padding: rfs-value(15px);
    gap: rfs-value(15px);

    @include media-breakpoint-up(md) {
      padding: rfs-value(25px 15px 23px);
    }

    &:focus {
      border-radius: 0;
      box-shadow: 0 0 0 2px #fff;
      outline: 2px black solid;
    }
  }
  :global(.accordion-button:not(.collapsed)) {
    color: black;
    background-color: #fff;
    box-shadow: none;
  }
  :global(.accordion-body) {
    padding: 15px;

    & > ul {
      list-style: none;
      padding-left: 0;
      margin: 0;

      & > li {
        margin-bottom: 10px;
      }
    }
  }
  .faq-header-section {
    &:last-child {
      margin-bottom: 0;
    }

    .faq-row {
      display: flex;
      flex-direction: row;
      justify-content: center;

      .faq-col {
        display: flex;
        flex-direction: column;
        padding-left: 30px;
        padding-right: 30px;

        &.faq-copy-wrap {
          -webkit-box-flex: 0;
          -ms-flex: 0 1 810px;
          flex: 0 1 810px;
        }
      }
    }
  }
  .faq-headline {
    font-size: rfs-value(48px);
    line-height: rfs-value(30px);
    font-weight: $font-weight-light;
    margin: 0;
  }
  .faq-panel-section {
    padding-bottom: rfs-value(60px);
  
    .content-title {
      font-weight: 700;
      color: #404040;
    }
    .content-title,
    .content-desc,
    .content-desc > a,
    .content-link {
      font-size: rfs-value(14px);
      line-height: rfs-value(22px);
    }

    :global(.accordion-header) {
      font-weight: $font-weight-semibold;
    }
  }
}
