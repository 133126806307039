@import 'styles/variables';
@import 'bootstrap/scss/mixins/breakpoints';
@import 'bootstrap/scss/vendor/rfs';

.container {
  padding-bottom: 60px;
  &:global(.container) {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.accordion-container {
  width: 100%;

  @include media-breakpoint-up(md) {
    &.media-on-right {
      margin-right: rfs-value(50px);
    }
    &.media-on-left {
      margin-left: rfs-value(50px);
    }
  }

  &:global(.accordion) {
    --bs-accordion-border-color: #969696;
    --bs-accordion-inner-border-radius: 0;
    --bs-accordion-border-radius: 0;
    --bs-accordion-active-color: black;
    --bs-accordion-btn-focus-box-shadow: 0 0 0 2px black;
    --bs-accordion-active-bg: transparent;
    --bs-accordion-bg: transparent;
    --bs-accordion-body-padding-x: 0;
  }

  :global(.accordion-button) {
    font-size: rfs-value(24px);
    padding: rfs-value(30px 0);
    line-height: rfs-value(25px);

    @include media-breakpoint-up(md) {
      font-size: calc(24px + (26 - 24) * ((100vw - 768px) / (1440 - 768)));
      line-height: 0.95;
    }

    &:focus {
      box-shadow: var(--bs-accordion-btn-focus-box-shadow) !important;
    }
    &:not(:global(.collapsed)) {
      box-shadow: none;
    }
  }

  .accordion-item {
    border-left: unset;
    border-right: unset;
  }

  :global(.accordion-body) {
    padding: rfs-value(10px 0 30px);
  }

  img {
    width: 75%;
    margin: rfs-value(0 auto 30px);
  }

  p {
    margin: 0;
    font-size: rfs-value(20px);
    line-height: 1.375;
  }
}

.accordion-images img {
  width: 75%;
  margin: 0 auto;
}
