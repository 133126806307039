@import 'styles/variables';
@import 'bootstrap/scss/mixins/breakpoints';

.image {
  display: block;
  object-fit: unset;
  width: 100%;

  @include media-breakpoint-up(xxl) {
    object-fit: cover;

    :global(.one-to-four-column) & {
      object-fit: unset;
    }
  }

  &:global(.marketing-hero) {
    height: 350px;
    object-fit: cover;

    @include media-breakpoint-up(md) {
      height: 640px;
    }
  }
  :global(.one-to-four-column.grid-1.layer-adjacent:not(.image-full-width-edge-to-edge)) & {
    width: auto;
    margin: 0 auto;

    @include media-breakpoint-up(md) {
      object-fit: fill;
    }
  }
  :global(.navigation-carousel) & {
    height: 100%;
    object-fit: none;
  }
}

.left {
  &.center {
    object-position: left center;
  }
  &.top {
    object-position: left top;
  }
  &.bottom {
    object-position: left bottom;
  }
}

.center {
  &.center {
    object-position: center center;
  }
  &.top {
    object-position: center top;
  }
  &.bottom {
    object-position: center bottom;
  }
}

.right {
  &.center {
    object-position: right center;
  }
  &.top {
    object-position: right top;
  }
  &.bottom {
    object-position: right bottom;
  }
}
