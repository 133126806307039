@import 'styles/variables';
@import 'bootstrap/scss/mixins/breakpoints';

.image-container {
  width: 100%;
  max-height: 750px;
  display: flex;
  justify-content: center;
  padding: 5rem;

  @include media-breakpoint-down(md) {
    padding: 1rem;
  }

  & > img {
    aspect-ratio: 20 / 17;
    width: 100%;
    max-width: 700px;
  }
}

.product-view-explorer {
  :global('.content-block') { 
    margin-bottom: 0; 
  }
}

.carousel {
  gap: 0 15px !important;

  @include media-breakpoint-down(md) {
    & > :global(.carousel-inner) {
      order: 1;
    }

    & > :global(.carousel-control-prev) {
      order: 3;
      margin-top: 30px;
    }

    & > :global(.carousel-control-next) {
      order: 3;
      margin-top: 30px;
    }
  }
}
