@import 'styles/variables';
@import 'bootstrap/scss/mixins/breakpoints';
@import 'bootstrap/scss/vendor/rfs';

.one-to-four-column-container {
  margin: 60px auto;

  & > :global(.container > .row) {
    @include media-breakpoint-up(md) {
      padding-right: calc(var(--bs-gutter-x) * 0.5);
      padding-left: calc(var(--bs-gutter-x) * 0.5);
    }

    & > :global([class*='col-'] + [class*='col-']) {
      margin-top: rfs-value(60px);

      @include media-breakpoint-up(md) {
        margin: 0;
      }
    }
  }

  &.grid-4 > :global(.container > .row > [class*='col-']) {
    &:nth-child(1),
    &:nth-child(2) {
      @include media-breakpoint-only(md) {
        margin-bottom: rfs-value(60px);
      }
    }
  }

  &:global(.grid-1) {
    &:global(.image-full-width-edge-to-edge),
    &:global(.layer-left),
    &:global(.layer-center),
    &:global(.layer-right) {
      & + :global(.image-full-width-edge-to-edge),
      & + :global(.layer-left),
      & + :global(.layer-center),
      & + :global(.layer-right) {
        margin-top: rfs-value(-60px);
      }
    }
  }

  &.bg {
    padding: 2rem 0;
    &-white {
      background-color: $nikon-white;
    }

    &-black {
      background-color: $nikon-black;
    }

    &-light-grey {
      background-color: $nikon-light-grey;
    }

    &-dark-grey {
      background-color: $nikon-bg-dark-grey;
    }

    &-yellow {
      background-color: $nikon-yellow;
    }
  }

  &.text {
    &-black {
      color: $nikon-black;
      & * {
        color: $nikon-black;
      }
    }

    &-white {
      color: $nikon-white;
      & * {
        color: $nikon-white;
      }
    }

    &-yellow {
      color: $nikon-yellow;
      & * {
        color: $nikon-yellow;
      }
    }
  }
}
