@import 'styles/variables';
@import 'bootstrap/scss/mixins/breakpoints';
@import 'bootstrap/scss/vendor/rfs';

.search-container {
  position: relative;
  overflow: hidden;

  @include media-breakpoint-up(lg) {
    overflow: visible;
  }
}

.search-field {
  background: $nikon-white-color;
  padding: 15px 25px;
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;

  @include media-breakpoint-up(lg) {
    display: flex;
    justify-content: flex-end;
    padding: 0;
    background: transparent;
    width: fit-content;
    margin-left: auto;
    overflow: visible;
  }

  input {
    border: none;
    font-family: Arial, sans-serif;
    font-size: 11px;
    font-weight: 700;
    height: 24px;
    padding: 2px 10px 3px 40px;
    border-radius: 90px;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.25);
    color: $nikon-grey;
    background-size: 22px 20px;

    @include media-breakpoint-up(lg) {
      width: 200px;
      margin: 10px 0 10px auto;
      height: 24px;
    }

    &::placeholder {
      color: $nikon-ada-grey;
      font-size: 11px;
      font-family: $font-family-serif;
      font-style: italic;
      font-weight: 400;
    }

    &:focus {
      border-color: transparent;
      box-shadow: 0 0 0 2px $nikon-white, 0 0 0 4px $nikon-black, 0 0 4px 8px $nikon-white !important;
      outline: 2px solid transparent;
      color: $nikon-grey;
    }
  }
}

.search-icon {
  position: absolute;
  left: 34px;

  @include media-breakpoint-up(lg) {
    left: 9px;
  }
}

.search-results {
  &-container {
    position: absolute;
    top: rfs-value(36px);
    width: 290px;
    right: 0;
    min-height: 50px;
    max-height: calc(100vh - 125px);
    overflow: scroll;
    background-color: $nikon-white;
    border: 1px solid $nikon-search-result-border;
    z-index: 1001;
  }

  &-title {
    font-size: rfs-value(24px);
    font-weight: $font-weight-medium;
    margin: rfs-value(20px 10px 10px);
    color: $nikon-dark-grey;
    text-align: right;
  }

  &-list {
    list-style: none;
    padding: 0;
    margin: 0;

    &-item {
      border-top: 1px solid $nikon-search-result-border;

      &:hover,
      &:focus,
      &:focus-within {
        background-color: $nikon-search-result-item-hover-bg;
      }

      a {
        padding: rfs-value(20px 18px 20px 10px);
        text-decoration: none;
        display: flex;
        gap: rfs-value(20px);
        outline: 0;
      }

      &-img {
        min-width: rfs-value(32px);
        width: rfs-value(32px);
        height: auto;
        align-self: flex-start;
        font-size: 10px;
        white-space: nowrap;
      }

      &-content {
        display: grid;
        gap: rfs-value(3px);
      }

      &-title,
      &-description {
        margin: 0;
        font-size: rfs-value(11px);
      }

      &-title {
        color: $nikon-link-bold;
        font-weight: $font-weight-bold;
        line-height: 1.3;
        letter-spacing: -0.11px;
      }

      &-description {
        color: $nikon-grey;
        font-weight: $font-weight-normal;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        white-space: normal;
        line-height: 1.35;
      }
    }
  }

  &-loader-container {
    display: flex;
    justify-content: center;
    padding: rfs-value(20px 10px);
    border-top: 1px solid $nikon-search-result-border;
  }
}
