@import 'styles/variables';
@import 'bootstrap/scss/mixins/breakpoints';
@import 'bootstrap/scss/vendor/rfs';

.nkn-lsn-item-row {
  box-sizing: border-box;
  color: #000;
  padding: 0;
  font-family: $headings-font-family;
}

.nkn-lsn-item-list-wrap {
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  padding: 0;
}

.nkn-lsn-item-list {
  display: flex;
  flex-direction: row;
  justify-content: center;
  list-style: none;
  margin: 0 auto;
  padding: 5px 0 15px;
}
.nkn-lsn-item {
  min-width: 130px;
  max-width: 150px;
  text-align: center;

  @include media-breakpoint-up(md) {
    min-width: 150px;
    max-width: 260px;
  }
}

.nkn-lsn-content-wrap {
  .nkn-lsn-a {
    color: #000;
    display: inline-block;
    padding: 0 15px;
    text-decoration: none;
    width: 100%;
    background-color: transparent;

    .nkn-lsn-image-wrap {
      margin-left: auto;
      margin-right: auto;
      width: 100px;
      position: relative;

      @include media-breakpoint-up(md) {
        width: 120px;
      }
    }

    img {
      transform: translate(0, 0);
      transition: transform 250ms ease-out;
      width: 100%;
      vertical-align: middle;
      border: 0;
    }

    .nkn-lsn-content {
      white-space: normal;

      span {
        display: inline-block;
        font-size: rfs-value(10px);
        font-weight: 700;
        letter-spacing: 1px;
        line-height: 1.2;
        padding: 6px 10px;
        text-transform: uppercase;
        white-space: normal;

        @include media-breakpoint-up(md) {
          font-size: rfs-value(14px);
        }
      }
    }

    &:focus,
    &:active {
      box-shadow: 0 0 0 2px #fff, 0 0 0 4px #000, 0 0 4px 8px #fff;
      outline: 2px solid transparent;
    }

    &:hover {
      img {
        transform: translate(0, -10%);
      }
    }
  }
}
.spacer {
  margin-bottom: 15px !important;
}
