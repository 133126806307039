@import 'styles/variables';
@import 'bootstrap/scss/mixins/breakpoints';
@import 'bootstrap/scss/vendor/rfs';

.lne-featured-collections-block {
  margin: rfs-value(40px 0);

  @include media-breakpoint-up(md) {
    padding: rfs-value(0 15px);
  }
  @include media-breakpoint-up(lg) {
    margin: rfs-value(60px 0);
  }

  h2 {
    margin-bottom: rfs-value(40px);
    text-align: center;
    font-size: rfs-value(30px);
    line-height: 1.2;

    @include media-breakpoint-up(lg) {
      margin-bottom: rfs-value(60px);
    }
  }
}

.collection {
  padding: rfs-value(0 15px 0 25px);
  text-align: center;
  margin-bottom: rfs-value(50px);

  @include media-breakpoint-up(md) {
    margin-bottom: rfs-value(70px);
  }

  a {
    color: $nikon-black;
    text-decoration: none;

    .image {
      transition: filter 0.25s ease-out;
    }
    &:hover {
      h3 {
        text-decoration: underline;
      }
      .image {
        filter: brightness(0.7);
      }
    }
  }
  .image {
    position: relative;
    margin-bottom: rfs-value(20px);
    transition: filter 0.25s ease-in-out;

    &:before,
    &:after {
      content: '';
      height: 100%;
      position: absolute;
      width: 100%;
      z-index: -1;
    }
    &:before {
      background: $nikon-light-grey-color;
      left: -10px;
      top: -10px;
    }
    &:after {
      background: $nikon-light-border;
      left: -5px;
      top: -5px;
    }
  }
  .info {
    h3 {
      font-size: rfs-value(24px);
      font-weight: 700;
      line-height: 1.2;
      margin: 0;
    }
    p {
      font-size: rfs-value(12px);
      font-weight: 700;
      letter-spacing: rfs-value(4px);
      line-height: 1.5;
      text-transform: uppercase;
      margin-bottom: rfs-value(10px);
    }
  }
}
