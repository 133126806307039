@import 'styles/variables';
@import 'bootstrap/scss/mixins';

.footNotes {
  margin: rfs-value(60px auto);
  max-width: 1170px;

  .text {
    position: relative;
    float: left;
    width: 100%;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px;
    margin: 0 0 rfs-value(10px);
    font-size: 65%;

    @include media-breakpoint-up(md) {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
