@import 'variables';

.container {
  display: flex;
  width: 100%;
  order: 2;
  padding-bottom: 60px;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  z-index: 1;

  & .item-container {
    padding: 5px;
    transition: border 0.2s ease-in-out;
    border-bottom: 4px solid transparent;
    cursor: pointer;

    &[data-color='yellow'] {
      &:global(.active) {
        border-bottom-color: #000;
      }

      &:hover {
        cursor: pointer;
        &:not(:global(.active)) {
          border-bottom-color: #fff;
        }
      }
    }

    &[data-color='black'] {
      &:global(.active) {
        border-bottom-color: $nikon-yellow-hover;
      }

      &:hover {
        cursor: pointer;
        &:not(:global(.active)) {
          border-bottom-color: #fff;
        }
      }
    }

    &[data-color='white'] {
      &:global(.active) {
        border-bottom-color: $nikon-yellow-hover;
      }

      &:hover {
        cursor: pointer;
        &:not(:global(.active)) {
          border-bottom-color: $nikon-light-grey;
        }
      }
    }
  }
}
