@import 'styles/variables';
@import 'bootstrap/scss/mixins/breakpoints';
@import 'bootstrap/scss/vendor/rfs';

.navigation-carousel {
  height: 200px;
  overflow: hidden;

  @include media-breakpoint-up(md) {
    height: 250px;
  }

  :global {
    .carousel {
      &,
      &-inner,
      &-item,
      & picture {
        height: 100%;
      }
    }
    .carousel-indicators {
      display: flex;
      width: fit-content;
      left: 50%;
      transform: translateX(-50%);
      margin: rfs-value(0 0 16px);

      &:before {
        content: '';
        display: block;
        background: rgba($nikon-text-dark-grey, 0.8);
        width: calc(100% - 40px);
        height: 32px;
        border-radius: 30px;
        position: absolute;
        bottom: 0;
        left: 0;
        top: auto;
        right: auto;
        z-index: 1;
      }

      button {
        border-radius: 100%;
        border: none;
        background-color: $nikon-white;
        opacity: 0.9;
        height: 14px;
        width: 14px;
        margin: rfs-value(9px 7px);
        position: relative;
        z-index: 2;

        &:first-child {
          margin-left: 10px;
        }
      }

      .active {
        background-color: $nikon-yellow !important;
      }
    }

    .carousel-item {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &.isSingle {
    :global {
      .carousel-indicators {
        display: none;
      }
    }
  }
}
