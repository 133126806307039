@import 'styles/variables';
@import 'bootstrap/scss/mixins/breakpoints';

.container {
  margin-bottom: 60px;
  margin-top: 0;
  padding: 0;
}

.heading {
  font-family: $headings-font-family;
  font-weight: 600;
  display: inline-block;
  font-size: 3rem;
  margin: 0;
  padding-bottom: 60px;
  padding-left: 15px;
  padding-right: 15px;
  text-align: center;
  width: 100%;
  line-height: 1.15;

  @include media-breakpoint-up(md) {
    font-size: 4rem;
  }
}
.tabs {
  list-style-type: none;
  margin: 0;
  padding: 0 15px;
  font-family: $headings-font-family;

  @include media-breakpoint-up(md) {
    column-count: 2;
    column-gap: 15px;
    column-fill: balance;
    padding: 0;
  }
  @include media-breakpoint-up(lg) {
    column-gap: 0;
  }
}

.tab-item {
  margin-bottom: 25px;
  line-height: 1.375;
  padding: 0 15px;
  @include media-breakpoint-up(md) {
    border-bottom: 52px solid transparent;
    float: none;
    margin-bottom: 0;
  }
  @include media-breakpoint-up(lg) {
    padding: 0 30px;
  }
}

.icon-wrap {
  margin-top: 4px;
  text-align: center;
  width: 42px;
  display: inline-block;
  vertical-align: top;

  @include media-breakpoint-up(md) {
    margin-top: 5px;
    width: 59px;
  }
}

.icon {
  height: auto;
  max-width: 42px;
  width: auto;
  opacity: 1;

  @include media-breakpoint-up(md) {
    max-width: 59px;
  }
}

.info-wrap {
  margin-left: 15px;
  width: calc(100% - 61px);
  display: inline-block;
  vertical-align: top;

  @include media-breakpoint-up(md) {
    margin-left: 30px;
    overflow: hidden;
    width: calc(100% - 91px);
  }
}

.title {
  font-size: 1.125rem;
  line-height: 1;
  margin: 0 0 15px;
  font-family: $headings-font-family;
  font-weight: 600;
}

.description {
  font-size: 1rem;
  font-family: $headings-font-family;
  color: #000;
  font-weight: 400;
  margin-bottom: 0;
}
