@import 'styles/variables';
@import 'bootstrap/scss/mixins/breakpoints';
@import 'bootstrap/scss/vendor/rfs';

.desktopIconNav {
  .headerFlyoutTrigger:first-child a {
    margin-right: rfs-value(15px);
  }

  .headerFlyoutTrigger {
    a.icon-link .icon-img {
      border-bottom: 2px solid transparent !important;
      transition: border-color 0.2s ease-out;
    }

    &:hover,
    &:focus {
      cursor: pointer;

      a.icon-link .icon-img {
        border-bottom: 2px solid $nikon-black !important;
      }
    }
  }

  :global(.nav-link) {
    padding: 0 !important;
  }

  @include media-breakpoint-up(lg) {
    position: absolute;
    z-index: 101;
    right: 0;
    background: #fff;
    margin-top: 16px;
  }
}

.headerFlyoutTrigger {
  // This is a hack to fix the issue with the flyout blinking on Safari
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  .flyout {
    transition-duration: 0.35s;
    transition-property: visibility;
    transition-timing-function: ease;
  }

  &:hover {
    visibility: visible;
    opacity: 1;
    transition-delay: 0.3s !important;
  }
}

.headerFlyoutTrigger:nth-child(1):hover {
  .flyout {
    @include media-breakpoint-up(lg) {
      display: block !important;
      right: rfs-value(-10px);
    }
  }

  .cart {
    @include media-breakpoint-up(lg) {
      display: none !important;
    }
  }
}

.headerFlyoutTrigger:nth-child(2):hover {
  .flyout {
    @include media-breakpoint-up(lg) {
      display: block !important;
      right: rfs-value(-25px);
    }
  }

  .account {
    @include media-breakpoint-up(lg) {
      display: none !important;
    }
  }
}

.cart-item-count {
  background: $nikon-yellow;
  border-radius: 90px;
  color: #000;
  font-family: $font-family-generic;
  font-size: rfs-value(9.996px);
  font-weight: $font-weight-bold;
  height: rfs-value(18px);
  line-height: 0;
  padding-top: rfs-value(9px);
  position: absolute;
  right: rfs-value(68px);
  text-align: center;
  top: rfs-value(19px);
  width: rfs-value(18px);

  @include media-breakpoint-up(lg) {
    right: rfs-value(1px);
    top: rfs-value(-3px);
  }
}

.account.unrecognized .content {
  padding: rfs-value(30px 30px 45px);

  .instruction {
    padding: 0;
  }
  button {
    white-space: nowrap;
  }
}

.account:not(.unrecognized) .content {
  padding-bottom: 0;

  .title {
    padding: rfs-value(30px) rfs-value(30px) 0;
  }

  .instruction {
    margin-bottom: rfs-value(30px);
  }
}

.account .content {
  .title {
    font-size: rfs-value(24px);
  }

  .instruction {
    font-family: $font-family-generic;
    font-size: rfs-value(14px);
    margin: rfs-value(25px) auto;
    padding: 0 rfs-value(30px);
  }
}

.cart .content {
  padding: rfs-value(15px) 0 rfs-value(45px);

  .title {
    font-size: rfs-value(50px);

    @include media-breakpoint-up(lg) {
      font-size: rfs-value(36px);
    }
  }

  .instruction {
    font-size: rfs-value(14px);
    text-transform: uppercase;

    @include media-breakpoint-up(lg) {
      font-size: rfs-value(20px);
      line-height: 1;
    }
  }
}

.flyout {
  display: block;
  position: absolute;
  text-align: center;
  top: rfs-value(55px);

  @include media-breakpoint-up(lg) {
    display: none;
    padding-top: rfs-value(35px);
    top: rfs-value(17px);

    &::before {
      border-bottom: rfs-value(20px) solid $nikon-light-grey;
      border-left: rfs-value(20px) solid transparent;
      border-right: rfs-value(20px) solid transparent;
      content: ' ';
      height: 0;
      position: absolute;
      right: rfs-value(23px);
      top: rfs-value(22px);
      width: 0;
    }
  }

  .empty-content {
    padding: rfs-value(45px) rfs-value(30px) !important;

    .instruction {
      margin-bottom: 25px !important;
      padding: 0;
    }
  }

  .content {
    text-align: center;
    word-wrap: break-word;
    background: $nikon-light-grey;

    @include media-breakpoint-up(lg) {
      min-width: 300px;
    }

    .title {
      font-family: $font-family-base;
      font-weight: $font-weight-light;
      line-height: 1;
      margin: 0 0 15px;
      padding: 0;

      @include media-breakpoint-up(lg) {
        margin: 0 0 25px;
      }
    }

    .instruction {
      @include media-breakpoint-up(lg) {
        font-weight: $font-weight-light;
      }
    }

    :global(.btn) {
      margin: 0 !important;
    }

    .cartItems {
      margin: 0;
      padding: 0;

      li {
        border-bottom: 1px solid $nikon-border-light-grey;
        display: block;
        padding: 15px 0 20px;
        text-align: left;

        &:first-child {
          padding-top: 10px;
          margin-right: 0;
        }
      }

      li * {
        vertical-align: top;
      }

      li img {
        display: inline-block;
        margin-left: 30px;
        max-width: 75px;
        width: 22%;
      }

      li label {
        display: inline-block;
        letter-spacing: 1px;
        margin-right: 30px;
        padding-left: 20px;
        padding-top: 10px;
        width: calc(78% - 60px);
        font-family: $font-family-generic;
      }

      li label strong {
        display: block;
        font-size: rfs-value(14px);
        font-weight: $font-weight-bold;
        letter-spacing: 2px;
        line-height: 1;
        margin: 0;
        padding-bottom: 5px;
        text-transform: uppercase;
      }

      li label span {
        display: block;
        font-size: rfs-value(12px);
        font-weight: $font-weight-normal;
        margin: 0;
        padding: 0;
        white-space: nowrap;
        text-transform: none;
      }
    }

    .seeAllProducts {
      display: inline-block;
      color: $nikon-link-bold;
      font-family: $font-family-generic;
      font-size: rfs-value(14px);
      font-weight: $font-weight-normal;
      padding-top: 7px;
      text-decoration: underline;
      text-transform: none;
      letter-spacing: 0;
    }

    .subtotal {
      -webkit-font-smoothing: antialiased;
      font-size: rfs-value(24px);
      font-weight: $font-weight-light;
      line-height: 1;
      padding: 0 30px;
      text-transform: uppercase;

      @include media-breakpoint-up(lg) {
        margin: 25px auto;
      }
    }

    .accountLinks,
    .guestLinks {
      border-top: rfs-value(1px) solid $nikon-border-light-grey;
      display: block;
      margin: 0;
      padding: 0;

      li {
        border-bottom: rfs-value(1px) solid $nikon-border-light-grey;
        display: block;
        height: calc(1.44rem + 41px);
        text-align: left;
      }

      li a {
        color: $nikon-black;
        display: inline-block;
        font-family: $font-family-generic;
        padding: rfs-value(20px) rfs-value(25px) !important;
        position: relative;
        vertical-align: top;
        width: 100%;

        &:hover .linkTitle {
          border-color: $nikon-black;
        }

        @include media-breakpoint-up(lg) {
          font-size: rfs-value(14px);
          font-weight: $font-weight-bold;
          height: calc(1.44rem + 40px);
          letter-spacing: rfs-value(1px);
          line-height: 1;
        }
      }

      .linkTitle {
        margin-bottom: rfs-value(-6px);
        border-bottom: rfs-value(2px) solid transparent;
        display: inline-block;
        padding-bottom: rfs-value(4px);
        transition: border-color 0.2s ease-out;
        vertical-align: top;

        @include media-breakpoint-up(lg) {
          font-weight: $font-weight-bold;
          letter-spacing: rfs-value(1.4px);
          margin-top: rfs-value(2px);
          text-transform: uppercase;
        }
      }
    }

    .guestLinks {
      border: none;
      margin-bottom: rfs-value(-30px);

      li {
        border-bottom: none;
        text-align: center;
      }
    }
  }
}
