.promo-banner {
  --background-color: #000;
  --text-color: #FFF;
  background: var(--background-color);


  :global {
    .carousel-control-prev-icon,
    .carousel-control-next-icon {
      background-size: 50%;
      margin-top: -10px;
    }
  }

  &.singleMessage {
    :global {
      .carousel-control-prev-icon,
      .carousel-control-next-icon {
        display: none;
      }
    }
  }

  a,
  p {
    display: block;
    color: var(--text-color);
    padding: 10px;
    font-size: 12px;
    line-height: 14px;
    font-weight: 700;
    text-align: center;
    letter-spacing: 1px;
    margin: 0;
  }
}
