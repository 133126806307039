@import 'styles/variables';
@import 'bootstrap/scss/mixins/breakpoints';
@import 'bootstrap/scss/vendor/rfs';

.container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;

  img {
    display: inline;
  }

  > div {
    width: 100%;
    height: 100%;
    padding: 0;
  }
}

.headline {
  position: absolute;
  color: white;
  width: 100%;
  padding: rfs-value(30px);

  img {
    margin-bottom: 10px;
  }

  h2 {
    margin: 0;
    font-family: $font-family-base;
    font-size: rfs-value(52px);
    line-height: 0.962;
    padding: 0;

    @include media-breakpoint-up(md) {
      font-size: calc(52px + (83 - 52) * ((100vw - 768px) / (1440 - 768)));
      line-height: 0.976;
    }
    @include media-breakpoint-up(xxl) {
      font-size: rfs-value(84px);
    }
  }
}

.bgOverlay {
  padding: 30px 25px;

  @include media-breakpoint-up(md) {
    padding: 40px 35px;
  }
}

.white {
  color: white;

  &.bgOverlay {
    background: rgb(0 0 0 / 65%);
  }
}

.black {
  color: black;

  &.bgOverlay {
    background: rgb(255 255 255 / 65%);
  }
}
