@import 'styles/variables';
@import 'bootstrap/scss/mixins/breakpoints';
@import 'bootstrap/scss/vendor/rfs';

.lne-featured-articles-block {
  :global(.row) {
    padding: 0;
  }

  @include media-breakpoint-up(md) {
    padding: rfs-value(0 15px);
  }
}

.article-block {
  a {
    display: block;
    color: $nikon-black;
    text-decoration: none;
    border-bottom: 1px solid $nikon-light-grey;
    margin-bottom: rfs-value(20px);
    padding-bottom: rfs-value(20px);

    @include media-breakpoint-up(md) {
      border: 0;
      margin: 0;
      padding: 0;
    }

    &:hover {
      text-decoration: none;
      color: $nikon-black;

      h3 {
        text-decoration: underline;
      }
    }
  }

  .image {
    position: relative;
    margin-bottom: rfs-value(15px);
    text-align: center;
    width: 100%;

    img {
      height: auto;
      max-height: 660px;
      max-width: 100%;
      vertical-align: bottom;
      width: 100%;
      object-fit: cover;
    }
    span {
      display: inline-block;
      font-size: rfs-value(12px);
      font-weight: 700;
      letter-spacing: rfs-value(2px);
      padding: rfs-value(8px 14px);
      text-transform: uppercase;
      position: absolute;
      right: 0;
      bottom: 0;
      text-align: left;
      width: 230px;
      background: $nikon-checkout-footer-bg;
      color: $nikon-dark-grey;

      &.advanced {
        background: $nikon-black;
        color: $nikon-white;
      }
      &.intermediate {
        background: $nikon-yellow;
        color: $nikon-dark-grey;
      }
    }
  }
  .category {
    margin-bottom: rfs-value(10px);
  }
  .category-text {
    font-size: rfs-value(12px);
    font-weight: 700;
    line-height: rfs-value(18px);
    text-transform: uppercase;
    letter-spacing: rfs-value(4px);
  }
  .new-badge {
    background: $nikon-yellow !important;
    color: $nikon-black;
    letter-spacing: rfs-value(1px);
    font-size: rfs-value(12px);
    text-transform: uppercase;
    margin-right: rfs-value(10px);
    border-radius: 22px;
    padding: rfs-value(5px 10px);
  }
  h3 {
    font-size: rfs-value(24px);
    font-weight: 600;
    line-height: 1.2;
  }
  .summary {
    margin: 0;
  }
}

.featured-article {
  h3 {
    font-size: rfs-value(30px);
  }
  .subtitle {
    font-size: rfs-value(18px);
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: rfs-value(8px);
  }
}

.sidebar {
  a:last-of-type {
    border: 0;
    margin: 0;
    padding: 0;
  }

  @include media-breakpoint-up(md) {
    a + a {
      border-top: 1px solid $nikon-light-grey !important;
      margin-top: rfs-value(20px) !important;
      padding-top: rfs-value(20px) !important;
    }
  }
}
