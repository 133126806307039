@import 'styles/variables';
@import 'bootstrap/scss/mixins/breakpoints';
@import 'bootstrap/scss/vendor/rfs';

.nkn-fb-container {
  position: relative;
  padding: 0;
  margin-bottom: rfs-value(60px);
  overflow: hidden;

  &:before {
    background: linear-gradient(90deg, #fff, hsla(0, 0%, 100%, 0));
    content: '';
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    width: 15px;
    z-index: 1;
  }
  &:after {
    background: linear-gradient(90deg, hsla(0, 0%, 100%, 0), #fff 75%);
    content: '';
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    width: 60px;
    z-index: 1;
  }
}

.wrapper {
  display: flex;
  overflow-x: auto;
}

.nkn-fb-tabs {
  display: flex;
  justify-content: flex-start;
  max-width: 100%;
  list-style-type: none;
  margin: 0 auto;
  padding: 0;
  color: black;
  font-family: $headings-font-family;
}

.nkn-fb-li {
  max-width: 225px;
  padding: rfs-value(0 15px);
  position: relative;

  span {
    display: block;
    text-align: center;
    font-family: $headings-font-family;
  }

  @include media-breakpoint-up(md) {
    padding: rfs-value(5px 15px);
  }
}

.nkn-fb-shortBand {
  font-weight: 600;
  font-size: rfs-value(36px);
  line-height: 1;
  white-space: nowrap;
}
.nkn-fb-longBand {
  margin-top: rfs-value(10px);
  font-size: rfs-value(18.6667px);
  line-height: 1;
}
