@import 'styles/variables';
@import 'bootstrap/scss/vendor/rfs';

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.scroll-smooth {
  scroll-behavior: smooth;
}

.slider-item {
  text-decoration: none;
  color: black;
  img {
    max-width: 360px;
    width: 360px;
    height: 318px;
  }
}

.scrollable-row {
  -ms-overflow-style: none;
  scrollbar-width: none;
  margin: 0;
  overflow-x: scroll;
  overflow-y: hidden;

  &::-webkit-scrollbar {
    display: none;
  }
}

.arrow {
  position: absolute;
  transform: translateY(-50%);
  cursor: pointer;
  background: #d3d3d3;
  width: rfs-value(45px);
  height: rfs-value(45px);
  border-radius: 50%;
  top: calc(50% - 15px);

  &:hover {
    background: #a19fa0;
    &.left {
      left: 35px;
      .icon {
        background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='white' d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/></svg>");
      }
    }
    &.right {
      right: 35px;
      .icon {
        background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='white' d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/></svg>");
      }
    }
  }

  .icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
    width: rfs-value(22px);
    height: rfs-value(22px);
  }
  &.left {
    left: 35px;

    .icon {
      background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='black' d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/></svg>");
    }
  }
  &.right {
    right: 35px;

    .icon {
      background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='black' d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/></svg>");
    }
  }

  &.fade-in {
    animation: fadeIn 1s;
    opacity: 1;
  }

  &.fade-out {
    animation: fadeOut 1s;
    opacity: 0;
  }
}

.test {
  text-decoration: none;
  color: black;

  img {
    max-width: 360px;
    width: 360px;
    height: 318px;
    object-fit: cover;
  }
}

.text-info {
  color: #000;
  font-family: $font-family-base;
  font-weight: 700;
  .title {
    font-size: rfs-value(18px);
    letter-spacing: 1px;
    line-height: 1;
    margin-bottom: 15px;
    margin-top: 0;
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
    text-transform: uppercase;
  }
  .description {
    font-size: calc(32px + (40 - 32) * ((100vw - 768px) / (1440 - 768)));
    line-height: 0.95;
  }
}
