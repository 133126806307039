@import 'styles/variables';
@import 'bootstrap/scss/mixins/breakpoints';
@import 'bootstrap/scss/vendor/rfs';

.header {
  position: relative;
  padding-bottom: 15px;
  z-index: 100;

  @include media-breakpoint-up(lg) {
    padding-bottom: 23px;
  }

  :global {
    .navbar {
      position: relative;
      padding: 14px 15px 0;

      @include media-breakpoint-up(lg) {
        padding: 30px 30px 0;
      }
    }

    .navbar-brand {
      position: relative;
      z-index: 102;
      padding: 0;

      @include media-breakpoint-up(lg) {
        position: absolute;
        top: 0;
        padding: 30px 0 0;
      }
    }
  }
}

.navIcons {
  display: flex;

  :global {
    .nav-link {
      margin-right: 18px;

      @include media-breakpoint-down(lg) {
        position: relative;
        top: 2px;
      }
    }
  }
}

.noSearchSpacer {
  display: block;
  width: 200px;
  height: 30px;
}

.navbarToggle {
  border: 0;
  padding: 0;

  &:focus {
    box-shadow: none;
  }

  :global {
    .navbar-toggler-icon {
      width: 35px;
      background-image: url(/icons/menuIcon.svg);
    }
  }
}

.navbarToggleClose {
  :global {
    .navbar-toggler-icon {
      background-image: url(/icons/closeIcon.svg);
    }
  }
}

.navContainer {
  @include media-breakpoint-up(lg) {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 25px 0 0;
    overflow-x: clip;
    z-index: 101;
  }
}

.navbarCollapse {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100vw;

  @include media-breakpoint-between(sm, lg) {
    width: 320px;
    left: auto;
    right: 0;
    max-height: calc(100vh - 125px);
    overflow: scroll;
    margin: 15px 0 0;
    background: transparent;
  }

  @include media-breakpoint-up(lg) {
    position: relative;
    width: calc(100vw - 60px);
    z-index: 101;
    flex-direction: column;
    align-items: flex-end;
  }
}

@include media-breakpoint-down(md) {
  .dropdown-menu {
    left: 100vw;
    width: 100vw;
  }
}

@media (max-width: 991px) and (min-width: 768px) and (max-width: 991px) {
  .dropdown-menu {
    left: 320px;
    width: 320px;
  }
}

@media (max-width: 991px) {
  .navbarCollapse .dropdown {
    height: calc(1.44em + 41px);
    pointer-events: auto;
    border-bottom: 1px solid #d8d8d8;
    display: block;
    background: #fff;
    position: absolute !important;
  }
}

.regionalText {
  background: #f2f2f2;
  font-size: 9px;
  line-height: 9px;
  text-align: right;
  font-family: $font-family-generic;
  padding: 5px 15px;
  color: #666;

  &.regionalTextDesktop {
    display: none;
  }

  @include media-breakpoint-up(lg) {
    background: none;
    font-size: 12px;
    line-height: 12px;
    padding: 0;
    letter-spacing: 0.1px;
    color: #404040;

    &.regionalTextMobile {
      display: none;
    }

    &.regionalTextDesktop {
      display: block;
    }
  }

  span {
    & + span:before {
      padding: 0 5px;
      content: '|';

      @include media-breakpoint-up(lg) {
        padding: 0 6px;
      }
    }
  }
}

.localText {
  text-decoration: none;
  color: #000;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.logo {
  height: 50px;
  width: auto;

  @include media-breakpoint-up(lg) {
    height: 68px;
  }
}

.skip-link {
  position: absolute;
  clip: rect(0 0 0 0) !important;
  margin: 0 !important;
  padding: 0.625rem 1.875rem;
  top: rfs-value(4px);
  left: 50%;
  transform: translateX(-50%);

  &:active,
  &:focus {
    clip: auto !important;
    opacity: 1;
    z-index: 1999999;
  }
}
