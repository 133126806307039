@import 'styles/variables';
@import 'bootstrap/scss/mixins/breakpoints';
@import 'bootstrap/scss/vendor/rfs';

.nkn-cl-info {
    max-width: 750px;
    margin: rfs-value(0 auto 30px);
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);

  &.down {
    margin: rfs-value(85px auto 0);
  }
}

.nkn-cl-headline {
  font-size: rfs-value(40px);
  line-height: 0.95;
  margin: rfs-value(0 0 15px);

  @include media-breakpoint-up(md) {
    font-size: calc(40px + (55 - 40) * ((100vw - 768px) / (1440 - 768)));
    line-height: 0.964;
  }

  @include media-breakpoint-up(xxl) {
    font-size: rfs-value(55px);
  }
}

.nkn-cl-description {
  margin: rfs-value(15px 0 0);
  font-size: rfs-value(16px);
  line-height: rfs-value(22px);
  font-family: $font-family-base;
}
