@import 'styles/variables';
@import 'bootstrap/scss/mixins';

.text-container {
  position: absolute;
  top: calc(50% - 30px);
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  padding: 0;

  @include media-breakpoint-up(md) {
    top: 50%;
  }

  h2 {
    font-size: rfs-value(30px);
    font-weight: 600;
    line-height: 1;
    margin: 0;

    @include media-breakpoint-up(md) {
      font-size: rfs-value(60px);
      line-height: rfs-value(62px);
    }
  }
  .description {
    margin-top: rfs-value(11px);
    font-size: rfs-value(14px);
    line-height: 1.375;
    font-weight: 400;

    @include media-breakpoint-up(md) {
      margin-top: rfs-value(15px);
      font-size: rfs-value(24px);
      line-height: 1.4;
    }
  }
  .video-button {
    margin-top: 15px;

    @include media-breakpoint-up(md) {
      margin-top: 30px;
    }
  }
}
