@import '../../../../styles/variables';
@import 'bootstrap/scss/vendor/rfs';
@import 'bootstrap/scss/mixins/breakpoints';

.navigationContainer {
  width: 100%;

  @include media-breakpoint-down(sm) {
    overflow: hidden;
  }
}

.navigationMenu {
  @include media-breakpoint-up(lg) {
    width: calc(100% - 130px);
  }

  @include media-breakpoint-down(lg) {
    transition: transform 0.25s linear 0s, max-height 0.25s ease-out;
    z-index: 100;

    &.showSubNav {
      transform: translateX(-100vw);
    }

    &.hideSubNav {
      transform: translateX(0vw);
    }
  }

  :global {
    .nav-item {
      @include media-breakpoint-down(lg) {
        position: initial;

        :global {
          .dropdown-menu {
            display: block;
            position: absolute;
            width: 320px;
            top: 0;
            left: 100vw;
            padding: 0;
            margin: 0;
            border: 0;
            background-color: $nikon-light-grey;
          }

          .dropdown-toggle[aria-expanded='false'] ~ .dropdown-menu {
            opacity: 0;
            transition: opacity;
            transition-delay: 0.25s;
            z-index: -1;
          }

          .dropdown-toggle[aria-expanded='true'] ~ .dropdown-menu {
            opacity: 1;
            transition-delay: 0s;
            z-index: 0;
          }
        }
      }

      @include media-breakpoint-down(md) {
        :global {
          .dropdown-menu {
            width: 100vw;
          }
        }
      }

      @include media-breakpoint-up(lg) {
        position: relative;
        padding-right: 34px;
        cursor: pointer;

        &:after {
          content: ' ';
          display: block;
          position: absolute;
          width: 100%;
          height: 100%;
          border: 0;
        }

        :global {
          .dropdown-menu {
            display: block;
            top: 35px;
            padding: 25px 15px 0 0;
            border: 0;
            border-radius: 0;
            background: $nikon-light-grey;
            opacity: 0;
            visibility: hidden;
            transition: opacity;
          }

          .dropdown-item {
            margin: 0 30px 29px;
          }
        }

        &:hover {
          :global {
            .dropdown-menu {
              transition-delay: 300ms;
              visibility: visible;
              opacity: 1;
            }
          }
        }
      }
    }

    .nav-link {
      text-decoration: none;
      font-family: $font-family-generic;
      text-transform: uppercase;
      font-size: rfs-value(14px);
      font-weight: $font-weight-bold;
      letter-spacing: 1.4px;
      line-height: 14px;
      color: $body-color;
      padding-bottom: 0;

      @include media-breakpoint-down(lg) {
        &:hover,
        &:focus {
          cursor: pointer;
          text-decoration: none;
        }
      }

      &:after {
        border: 0;
      }

      @include media-breakpoint-up(lg) {
        padding-left: 0 !important;
        padding-right: 0 !important;
        text-decoration: underline 2px rgb(0, 0, 0, 0);
        text-underline-offset: 4px;
        transition: text-decoration-color 0.2s ease-out;

        &:hover,
        &:focus {
          text-decoration: underline 2px rgb(0, 0, 0, 1);
        }
      }

      @include media-breakpoint-down(lg) {
        position: initial;
        width: 100vw;
        height: calc(1.44em + 40px);
        padding: 22px 25px;
        border-bottom: 1px solid #d8d8d8;
        background: #fff;
        pointer-events: auto;

        overflow: hidden;
        vertical-align: top;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 2px;
        text-transform: uppercase;

        &:after {
          content: url(/icons/rightArrow.svg);
          display: inline-block;
          height: 10px;
          position: absolute;
          right: 25px;
          width: 6px;
        }
      }
    }

    .dropdown-item {
      padding-bottom: 0;
      font-family: $font-family-generic;
      text-transform: uppercase;
      font-size: rfs-value(14px);
      font-weight: $font-weight-bold;
      letter-spacing: 1.4px;
      line-height: 14px;
      color: $body-color;
      text-decoration: underline 2px rgb(0, 0, 0, 0);
      text-underline-offset: 4px;
      transition: text-decoration-color 0.2s ease-out;

      &:hover,
      &:focus {
        text-decoration-color: rgb(0, 0, 0, 1);
        background: transparent;
      }

      @include media-breakpoint-down(lg) {
        display: inline-block;
        margin: 0;
        padding: 20px 25px;
        font-size: rfs-value(12px);
        font-weight: $font-weight-normal;
        letter-spacing: 0;
        text-transform: none;
        background-color: $nikon-light-grey;
        border-bottom: thin solid $nikon-light-grey-border;
      }
    }
  }

  .dropdownMenuTitle {
    display: none;

    @include media-breakpoint-down(lg) {
      display: block;
      background: #fff;
      font-weight: $font-weight-bold;
      text-transform: uppercase;
      border-bottom: 2px solid #d8d8d8;
      line-height: 1;
      padding: 20px 25px;
      text-align: center;

      &:before {
        border: 0 !important;
        content: url(/icons/leftArrow.svg);
        display: inline-block;
        height: 10px;
        position: absolute;
        left: 25px;
        width: 6px;
      }

      &:hover {
        text-decoration: none;
        background: #fff;
      }
    }
  }
}
