@import 'styles/variables';
@import 'bootstrap/scss/mixins/breakpoints';
@import 'bootstrap/scss/vendor/rfs';

.quickLinkContainer {
  padding-top: 30px;
}

.section-title {
  font-size: rfs-value(40px);
  line-height: 0.95;
  margin-bottom: rfs-value(60px);
  padding: 0 $grid-gutter-width/2;

  @include media-breakpoint-up(md) {
    font-size: calc(40px + (55 - 40) * ((100vw - 768px) / (1440 - 768)));
    line-height: 0.964;
  }
  @include media-breakpoint-up(xxl) {
    font-size: rfs-value(55px);
  }
}

.quick-link {
  text-decoration: none;
  color: black;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 calc(50% - 20px);
  flex: 0 0 calc(50% - 20px);
  max-width: 390px;
  min-width: 230px;
  cursor: pointer;

  .link-wrap {
    padding: rfs-value(0 15px);

    &:hover .play-btn {
      background-color: rgba(0, 0, 0, 0.95);
    }
  }
  .play-btn {
    pointer-events: none;
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 50%;
    display: block;
    height: 72px;
    left: 33px;
    top: 18px;
    width: 72px;
    position: absolute;
    transition: background-color 250ms ease-out;
    z-index: 999;

    span {
      color: #fff;
      font-size: 2em;
      left: 50%;
      margin-left: 3px;
      margin-top: 4px;
      top: 50%;
      transform: translate(-50%, -50%);
      pointer-events: none;
      position: absolute;
    }
  }

  .img-wrap {
    height: 150px;
    min-width: 200px;
    margin: auto;
    overflow: hidden;

    @include media-breakpoint-up(md) {
      height: 318px;
      max-width: 360px;
    }

    img {
      position: relative;
      width: 100%;
      height: auto;
      max-height: none;
      min-height: 150px;
      margin: 0 auto;
      object-fit: cover;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      @include media-breakpoint-up(md) {
        min-height: 318px;
        width: 100%;
      }
    }
  }
}

.text-info {
  color: #000;
  font-family: $font-family-base;
  font-weight: 700;
  margin-bottom: rfs-value(10px);

  .title {
    font-size: rfs-value(18px);
    letter-spacing: 1px;
    line-height: 1;
    margin-bottom: 15px;
    margin-top: 0;
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
    text-transform: uppercase;
  }
  .description {
    font-size: rfs-value(24px);
    line-height: 0.95;
    margin-bottom: 0;

    @include media-breakpoint-up(md) {
      font-size: rfs-value(40px);
    }
  }
}

.image-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.video-container {
  width: 75%;
  max-width: 70vw;
  left: 50%;
  position: relative;
  top: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}
