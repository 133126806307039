@import 'styles/variables';
@import 'bootstrap/scss/mixins';

$video-height: 350px;
$video-height-medium-up: 640px;

.full-width-video {
  height: $video-height;
  overflow: hidden;

  @include media-breakpoint-up(md) {
    height: $video-height-medium-up;
  }

  :global(.carousel-indicators) {
    display: flex;
    width: fit-content;
    left: 50%;
    transform: translateX(-50%);
    margin: rfs-value(0 0 16px);

    &:before {
      content: '';
      display: block;
      background: rgba($nikon-text-dark-grey, 0.8);
      width: 100%;
      height: 32px;
      border-radius: 30px;
      position: absolute;
      bottom: 0;
      left: 0;
      top: auto;
      right: auto;
      z-index: 1;
    }

    button {
      border-radius: 100%;
      border: none;
      background-color: $nikon-white;
      opacity: 0.9;
      height: 14px;
      width: 14px;
      margin: rfs-value(9px 7px);
      position: relative;
      z-index: 2;

      &:first-child {
        margin-left: 10px;
      }
      &:last-child {
        margin-right: 10px;
      }
    }

    :global(.active) {
      background-color: $nikon-yellow !important;
    }
  }
}

.indicators-hidden {
  :global(.carousel-indicators) {
    display: none;
  }
}

.video-container {
  padding-top: $video-height;

  @include media-breakpoint-up(md) {
    padding-top: $video-height-medium-up;
  }
  position: relative;
  width: 100%;
  height: auto;
  overflow: hidden;
  color: #ffffff;
}

.hero-container {
  min-height: 350px;
}
