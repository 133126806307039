@import '../../../../../styles/variables';
@import 'bootstrap/scss/mixins';

.hotspots-content-img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 225px;
  margin-bottom: 30px;
  width: 100%;
}

.hotspots-content-title {
  font-weight: $font-weight-semibold;
  font-size: rfs-value(28px);
  line-height: 0.875;
  color: $body-color;
  margin-bottom: 15px;
  font-family: $headings-font-family;

  @include media-breakpoint-up(md) {
    font-size: calc(32px + (40 - 32) * ((100vw - 768px) / (1440 - 768)));
    line-height: 0.95;
  }
  @include media-breakpoint-up(xxl) {
    font-size: rfs-value(38px);
  }
}

.hotspots-content-description {
  font-size: rfs-value(18px);
  font-family: $headings-font-family;
  color: $body-color;
}

.wrapper {
  &:focus {
    outline: none;
  }
}
