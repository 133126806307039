@import '../../../../styles/variables';
@import 'bootstrap/scss/mixins/breakpoints';
@import 'bootstrap/scss/vendor/rfs';

.footerLinks {
  padding: 20px 20px 50px;

  h3 {
    font-size: rfs-value(24px);
    font-weight: 300;
    margin-bottom: 20px;
    line-height: 1.3;
    color: $nikon-dark-grey;
  }

  ul {
    padding: 15px 0 0 0;
    margin: 0 auto 30px;
    list-style: none;

    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
    }
  }

  li {
    width: 100%;
    margin-bottom: 20px;
    font-size: rfs-value(12px);
    line-height: 16px;
  }

  a {
    color: black !important;
    text-decoration: none;
    font-family: $font-family-generic;
    font-size: rfs-value(12px) !important;

    &:hover {
      text-decoration: underline;
    }
  }

  i {
    font-size: rfs-value(12px);
    -webkit-text-stroke-width: 1px;
    transform-origin: center;
    transition: transform 0.2s ease-in;

    &.open {
      transform: rotate(180deg);
    }
  }
}
