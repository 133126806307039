@font-face {
  font-family: GT-Eesti-Pro-Display;
  font-weight: 200;
  src: url(/fonts/GT-Eesti-Pro-Display/GT-Eesti-Pro-Display-Thin.eot);
  src: url(/fonts/GT-Eesti-Pro-Display/GT-Eesti-Pro-Display-Thin.eot?#iefix) format('embedded-opentype'),
    url(/fonts/GT-Eesti-Pro-Display/GT-Eesti-Pro-Display-Thin.woff2) format('woff2'),
    url(/fonts/GT-Eesti-Pro-Display/GT-Eesti-Pro-Display-Thin.woff) format('woff'),
    url(/fonts/GT-Eesti-Pro-Display/GT-Eesti-Pro-Display-Thin.ttf) format('truetype');
}

@font-face {
  font-family: GT-Eesti-Pro-Display;
  font-style: normal;
  font-weight: 300;
  src: url(/fonts/GT-Eesti-Pro-Display/GT-Eesti-Pro-Display-Thin.eot);
  src: url(/fonts/GT-Eesti-Pro-Display/GT-Eesti-Pro-Display-Thin.eot?#iefix) format('embedded-opentype'),
    url(/fonts/GT-Eesti-Pro-Display/GT-Eesti-Pro-Display-Thin.woff2) format('woff2'),
    url(/fonts/GT-Eesti-Pro-Display/GT-Eesti-Pro-Display-Thin.woff) format('woff'),
    url(/fonts/GT-Eesti-Pro-Display/GT-Eesti-Pro-Display-Thin.ttf) format('truetype');
}

@font-face {
  font-family: GT-Eesti-Pro-Display;
  font-style: normal;
  font-weight: 400;
  src: url(/fonts/GT-Eesti-Pro-Display/GT-Eesti-Pro-Display-Regular.eot);
  src: url(/fonts/GT-Eesti-Pro-Display/GT-Eesti-Pro-Display-Regular.eot?#iefix) format('embedded-opentype'),
    url(/fonts/GT-Eesti-Pro-Display/GT-Eesti-Pro-Display-Regular.woff2) format('woff2'),
    url(/fonts/GT-Eesti-Pro-Display/GT-Eesti-Pro-Display-Regular.woff) format('woff'),
    url(/fonts/GT-Eesti-Pro-Display/GT-Eesti-Pro-Display-Regular.ttf) format('truetype');
}

@font-face {
  font-family: GT-Eesti-Pro-Display;
  font-weight: 600;
  src: url(/fonts/GT-Eesti-Pro-Display/GT-Eesti-Pro-Display-Bold.eot);
  src: url(/fonts/GT-Eesti-Pro-Display/GT-Eesti-Pro-Display-Bold.eot?#iefix) format('embedded-opentype'),
    url(/fonts/GT-Eesti-Pro-Display/GT-Eesti-Pro-Display-Bold.woff2) format('woff2'),
    url(/fonts/GT-Eesti-Pro-Display/GT-Eesti-Pro-Display-Bold.woff) format('woff'),
    url(/fonts/GT-Eesti-Pro-Display/GT-Eesti-Pro-Display-Bold.ttf) format('truetype');
}

@font-face {
  font-family: open sans;
  font-style: normal;
  font-weight: 300;
  src: url(/fonts/OpenSans/OpenSans-Thin.eot);
  src: url(/fonts/OpenSans/OpenSans-Thin.eot?#iefix) format('embedded-opentype'),
    url(/fonts/OpenSans/OpenSans-Thin.woff2) format('woff2'),
    url(/fonts/OpenSans/OpenSans-Thin.woff) format('woff'),
    url(/fonts/OpenSans/OpenSans-Thin.ttf) format('truetype');
}

@font-face {
  font-family: open sans;
  font-style: normal;
  font-weight: 400;
  src: url(/fonts/OpenSans/OpenSans-Regular.eot);
  src: url(/fonts/OpenSans/OpenSans-Regular.eot?#iefix) format('embedded-opentype'),
    url(/fonts/OpenSans/OpenSans-Regular.woff2) format('woff2'),
    url(/fonts/OpenSans/OpenSans-Regular.woff) format('woff'),
    url(/fonts/OpenSans/OpenSans-Regular.ttf) format('truetype');
}

@font-face {
  font-family: open sans;
  font-weight: 600;
  src: url(/fonts/OpenSans/OpenSans-Bold.eot);
  src: url(/fonts/OpenSans/OpenSans-Bold.eot?#iefix) format('embedded-opentype'),
    url(/fonts/OpenSans/OpenSans-Bold.woff2) format('woff2'),
    url(/fonts/OpenSans/OpenSans-Bold.woff) format('woff'),
    url(/fonts/OpenSans/OpenSans-Bold.ttf) format('truetype');
}
