@import 'styles/variables';
@import 'bootstrap/scss/mixins/breakpoints';
@import 'bootstrap/scss/vendor/rfs';

.container {
  margin-bottom: 60px;
  display: flex;
  justify-content: center;
  max-width: 100% !important;

  .row {
    max-width: 750px !important;
  }

  &.bg {
    padding: 2rem 0;
    &-white {
      background-color: $nikon-white;
    }

    &-black {
      background-color: $nikon-black;
    }

    &-light-grey {
      background-color: $nikon-light-grey;
    }

    &-dark-grey {
      background-color: $nikon-bg-dark-grey;
    }

    &-yellow {
      background-color: $nikon-yellow;
    }
  }

  &.text {
    &-black {
      color: $nikon-black;
      & * {
        color: $nikon-black;
      }
    }

    &-white {
      color: $nikon-white;
      & * {
        color: $nikon-white;
      }
    }

    &-yellow {
      color: $nikon-yellow;
      & * {
        color: $nikon-yellow;
      }
    }
  }
}

.nkn-fw-heading {
  font-size: rfs-value(40px);
  line-height: 0.95;
  font-weight: 600;
  font-family: $headings-font-family;
  margin-bottom: 15px;

  @include media-breakpoint-up(md) {
    font-size: calc(40px + (55 - 40) * ((100vw - 768px) / (1440 - 768)));
    line-height: 0.964;
  }
  @include media-breakpoint-up(xxl) {
    font-size: rfs-value(55px);
  }
}

.nkn-fw-subhead {
  font-family: $headings-font-family;
  font-size: rfs-value(24px);
  font-weight: 600;
  color: #000;
  margin-bottom: 15px;
}

.nkn-fw-description {
  margin: 0;
  font-size: rfs-value(16px);
  font-weight: 400;
  color: #000;
  line-height: 1.375;
  font-family: $headings-font-family;
}

.nkn-fw-tabs {
  margin: 25px 0 0;
  padding: 0;
}
