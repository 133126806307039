@import 'styles/variables';
@import 'bootstrap/scss/mixins/breakpoints';
@import 'bootstrap/scss/vendor/rfs';

.nkn-cl-container {
  padding: 0;

  :global(.image-carousel-contained) {
    @include media-breakpoint-up(lg) {
      width: calc(100% - 170px);
      max-width: 1170px;
      margin: 0 auto;

      :global(.carousel-inner) {
        padding-left: 60px;
        padding-right: 60px;
      }
      :global(.carousel-control-prev) {
        left: -60px !important;
      }
      :global(.carousel-control-next) {
        position: absolute;
        right: -60px !important;
      }
      :global(.carousel-control-prev),
      :global(.carousel-control-next) {
        position: absolute;
        bottom: auto !important;
        top: calc(50% - 22.5px) !important;
      }
    }
  }

  :global(.carousel) {
    :global(.carousel-inner) {
      margin: 0 auto;
      padding: 0;
      width: 100%;
      overflow: visible !important;
    }
    :global(.nkn-cl-img) {
      height: 100%;
      min-height: 350px;
      min-width: 100%;
      object-fit: cover;
      object-position: center;

      @include media-breakpoint-up(md) {
        min-height: 640px;
      }
    }
  }
}
