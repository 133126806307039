@import 'styles/variables';
@import 'bootstrap/scss/mixins';

.hotspot-main-container {
  width: 100%;
  margin-bottom: 60px;
  margin-left: auto;
  margin-right: auto;

  &:after,
  &:before {
    content: ' ';
    display: table;
  }

  @include media-breakpoint-up(md) {
    max-width: 750px;
  }

  @include media-breakpoint-up(lg) {
    max-width: 970px;
  }
}
.hotspot-heading-container {
  width: 100%;
  margin-bottom: 60px;
  margin-left: auto;
  margin-right: auto;

  &:after,
  &:before {
    content: ' ';
    display: table;
  }

  @include media-breakpoint-up(md) {
    max-width: 750px;
  }

  @include media-breakpoint-up(lg) {
    max-width: 970px;
  }
  @include media-breakpoint-up(xl) {
    max-width: 1170px;
  }
}
.hotspots-heading-wrapper {
  padding-left: 0;
  padding-right: 0;
  margin: 0 auto;
  max-width: 750px;
}

.hotspots-heading {
  font-size: rfs-value(38px);
  line-height: 0.95;
  font-weight: $font-weight-semibold;

  @include media-breakpoint-up(md) {
    font-size: calc(40px + (55 - 40) * ((100vw - 768px) / (1440 - 768)));
    line-height: 0.964;
  }

  @include media-breakpoint-up(xl) {
    font-size: rfs-value(53px);
  }
}

.hotspot-wrap {
  text-align: center;
  position: relative;
}
.hotspots-main-img-wrap {
  display: inline-block;
  position: relative;
  text-align: center;
}

.hotspots-main-img {
  margin: 0 auto;
  width: 260px;
  @include media-breakpoint-up(md) {
    width: 380px;
  }
  @include media-breakpoint-up(lg) {
    width: 550px;
  }
}

.hotspots-point-wrapper {
  position: absolute;
  font-size: rfs-value(12px);
  left: calc(0.4727 * var(--left-alignment));
  top: calc(0.4727 * var(--top-alignment));

  &::before {
    position: absolute;
    background: $nikon-light-grey-border;
    border-radius: 50%;
    content: ' ';
    height: 40px;
    transition: background 0.25s ease-out;
    width: 40px;
    transform: translate(-50%, -50%);
    cursor: pointer;
    left: 50%;
    top: 50%;
  }

  &:focus {
    outline: none;
    &::before {
      border: 1px solid rgba(91, 157, 217, 0.8);
      box-shadow: 0 0 9px #5b9dd9;
    }
  }

  @include media-breakpoint-up(md) {
    left: calc(0.6909 * var(--left-alignment));
    top: calc(0.6909 * var(--top-alignment));
    &:hover {
      &::before {
        background: $nikon-yellow;
      }
    }
    &:active {
      &::before {
        background: $nikon-yellow;
      }
    }
  }
  @include media-breakpoint-up(lg) {
    left: var(--left-alignment);
    top: var(--top-alignment);
  }
}
.hotspots-point {
  color: $body-color;
  font-size: rfs-value(18px);
  height: 18px;
  left: -9px;
  line-height: 24px;
  pointer-events: none;
  position: absolute;
  top: -9px;
}

.hotspot-active {
  &::before {
    background: $nikon-yellow !important;
  }
}

.hotspots-content-wrap-model {
  .hotspots-content-description {
    margin-bottom: 15px;
  }
}

.hotspots-modal {
  :global {
    .modal-dialog {
      margin: 10px;
      position: relative;
      width: auto;
      @include media-breakpoint-up(sm) {
        max-width: none;
      }
    }
    .modal-content {
      border-radius: 2px;
      padding: 20px;
      background-clip: padding-box;
      background-color: $nikon-bg-color;
      border: 1px solid rgba(0, 0, 0, 0.2);
      -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
      box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
      outline: 0;
    }
    .modal-body {
      padding: 0;
    }

    .modal-footer {
      border-top: none;
      padding: 10px 0 0;
      text-align: center;

      &::after,
      &::before {
        content: ' ';
        display: table;
      }
      &::after {
        clear: both;
      }
    }
  }

  .hotspots-close-btn {
    font-size: rfs-value(18px);
    background-color: $nikon-bg-color;
    border-color: $nikon-light-grey-border;
    border-radius: 0 0 2px 2px;
    color: $nikon-dark-grey;
    font-weight: $font-weight-bold;
    line-height: 1.45;
    padding: 16px 12px;
    width: 100%;
  }
}
